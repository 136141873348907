<nav class="navDefault" fxLayout fxLayoutAlign="space-between center">
  <div class="navDefault_container">
    <button routerLink="/kore/metodo" class="navDefault_container_button" mat-button>sobre
      <span class="caret"></span>
    </button>
    <button routerLink="/unidades" class="navDefault_container_button" mat-button>studios
      <span class="caret"></span>
    </button>
    <!--  <button routerLink="/instrutores" class="navDefault_container_button" mat-button>instrutores
      <span class="caret"></span>
    </button> -->
    <!-- <button (click)="goToPage('https://www.franquiakore.com.br/')" class="navDefault_container_button lb"
      mat-button>franquias
      <span class="caret"></span>
    </button> -->
    <button routerLink="/kore-eventos/kore-fidelidade" class="navDefault_container_button lb" mat-button>programa fidelidade
      <span class="caret"></span>
    </button>
    <button routerLink="/kore-eventos" class="navDefault_container_button lb" mat-button>kore news
      <span class="caret"></span>
    </button>
    <!-- <button (click)="goToPage('https://aulas.sweatify.com.br/cadastro')" class="navDefault_container_button lb"
      mat-button>kore at home
      <span class="caret"></span>
    </button> -->


    <ng-container *ngIf="isLoggedIn">
      <button routerLink="/minha-conta/bring-a-friend" class="navDefault_container_button lb" mat-button>bring a friend
        <span class="caret"></span>
      </button>
    </ng-container>

    <ae2-bag>
      <ng-template #templateBagRef let-total="total">
        <button routerLink="/checkout" class="navDefault_container_button bi" mat-button>
          <i class="bai">{{total}}</i>
        </button>
      </ng-template>
    </ae2-bag>
    <ng-container *ngIf="isLoggedIn">
      <button (click)="logOut()" class="navDefault_container_button lb" mat-button>sair
        <span class="caret"></span>
      </button>
    </ng-container>

  </div>
  <div class="navDefault_container_right" fxLayout>
    <ng-container *ngIf="isLoggedIn">
      <ng-container *ngIf="!authStore?.isImpersonate">
        <div fxLayout fxLayoutAlign="center center">
          <div class="ae-total-name">
            <button routerLink="/minha-conta/meus-dados" mat-button>{{authStore?.user?.firstName}} </button>
          </div>

          <ng-container *ngIf="authStore?.user?.unitInfo?.vaccineNeeded && isShowButtonMask">
            <div>
              <button class="noShadow covid19" mat-mini-fab (click)="openModalCovid()">
                <div class="maskImage {{colorButtonFloat()}}"></div>
              </button>
            </div>
          </ng-container>

          <ae2-total-credits-component [positionX]="start">
            <ng-template #templateTotalCredits let-data>
              <div class="ae-total-credits">
                <ng-container *ngIf="data?.isLogged">

                  <ng-container *ngIf="data?.isUnlimited">
                    <button class="noShadow" mat-mini-fab color="primary" (click)="data?.openPopOver()">
                      <mat-icon>all_inclusive</mat-icon>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="!data?.isUnlimited">
                    <button class="noShadow" mat-mini-fab color="primary" (click)="data?.openPopOver()">
                      {{data?.totalCredits}}
                    </button>
                  </ng-container>

                </ng-container>
              </div>
            </ng-template>
            <ng-template #templateTotalCreditsPopOver let-data>
              <div class="totalCredits">
                <div class="totalCredits-button">
                  <mat-icon class="totalCredits-button-close" (click)="data?.closeOverlay()">close</mat-icon>
                </div>
                <div class="totalCredits-box">
                  <p class="totalCredits-box-title">Créditos Disponíveis</p>
                  <mat-list role="list">
                    <ng-container *ngFor="let package of data?.packages ">
                      <mat-list-item class="totalCredits-box-itemlist" role="listitem">
                        <div class="totalCredits-box-itemlist-item">
                          {{package?.creditTypeName}}
                        </div>
                        <div class="totalCredits-box-itemlist-number">
                          <ng-container *ngIf="package?.isUnlimited">
                            <span class="infinityIcon">&infin;</span>
                          </ng-container>
                          <ng-container *ngIf="!package?.isUnlimited">
                            {{package?.creditsAvailable}}
                          </ng-container>
                        </div>
                      </mat-list-item>
                    </ng-container>
                  </mat-list>
                  <div class="totalCredits-buy">
                    <button class="noShadow boldClass" mat-raised-button color="primary" routerLink="/pacotes">
                      comprar mais
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </ae2-total-credits-component>
        </div>
      </ng-container>
      <ng-container *ngIf="authStore?.user?.isImpersonate">

        <div fxLayout fxLayoutAlign="center center">
          <div class="ae-total-name">
            <button routerLink="/minha-conta/meus-dados" mat-button>{{ authStore?.user?.impersonatorName }}
              ({{ authStore?.user?.firstName }})</button>
          </div>
          <div class="ae-total-credits">{{authStore?.totalCredits ? authStore?.totalCredits : '0'}}</div>
        </div>
      </ng-container>
    </ng-container>


    <ng-container *ngIf="!isLoggedIn">
      <div class="navDefault_container_right_content">
        <button (click)="openInSignIn()" class="navDefault_container_right_content_button" mat-button>login
          <span class="caret"></span>
        </button>
        <button (click)="openInSignUp()" class="navDefault_container_right_content_button" mat-button>cadastro
          <span class="caret"></span>
        </button>
      </div>
    </ng-container>
    <div class="navDefault_container_right_content butons-right">
      <button routerLink="/pacotes" class="navDefault_container_right_content_button color-white boldClass"
        mat-button>comprar
        <span class="caret"></span>
      </button>
      <button class="navDefault_container_right_content_button color-white boldClass" mat-button
        (click)="openPopOver()">
        <app-unit-selection-component [openInComponent]="false"></app-unit-selection-component>
        <span class="caret"></span>
      </button>
    </div>
  </div>
</nav>